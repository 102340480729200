/* Helpers
  =============== */

// Classic clearfix 
.clearfix::before,
.clearfix::after {
	content: " ";
	display: block;
}

.clearfix:after {
	clear: both;
}

// Text alignment
.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-justify {
	text-align: justify;
}

// Display
.block {
	display: block;
}

.inline-block {
	display: inline-block;
}

.inline {
	display: inline;
}

// Vertical center
.vertical-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

// Responsive images
.responsive-image {
	width: 100%;
}

// Display State
.show {
	display: block !important;
}

.hide {
	display: none !important;
}

.invisible {
	visibility: hidden;
}

// Floats
.float-left {
	float: left;
}

.float-right {
	float: right;
}

// Padding
.no-padding-top {
	padding-top: 0;
}

.no-padding-bottom {
	padding-bottom: 0;
}

.padding-top {
	padding-top: $padding;
}

.padding-bottom {
	padding-bottom: $padding;
}

// Margins
.no-margin-top {
	margin-top: 0;
}

.no-margin-bottom {
	margin-bottom: 0;
}

.margin-top {
	margin-top: $padding;
}

.margin-bottom {
	margin-bottom: $padding;
}

// Backgrounds
.alternate-background {
	background: $alternate-background;
	color: $alternate-color;
}

.colored-background {
	background: $primary-color;
	color: #fff;

	a,h1,h2,h3,h4,h5 {
		color: #fff;
	}

	a:hover {
		color: $secondary-color;
	}

}

.background-img {
	background-size: cover;
	background-position: center;
}

// Screen reader text for accessibility
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}