/* Navigation
  =============== */

// Navigation Variables
$content-width: $large;
$breakpoint: $small;
$nav-height: 70px;
$nav-background: $background;
$nav-font-color: $primary-color;
// Outer navigation wrapper
.navigation {
    height: $nav-height;
    background: $nav-background;
    font-weight: bold;
    border-bottom: 4px solid darken($alternate-background, 5%);
}

// Logo and branding
.brand {
    position: absolute;
    padding-left: 20px;
    float: left;
    line-height: $nav-height;
    text-transform: uppercase;
    font-size: 1.4em;
    a,
    a:visited {
        color: $nav-font-color;
        text-decoration: none;
    }
    img {
        width: 150px;
        margin-top: 10px;
    }
}

// Container with no padding for navbar
.nav-container {
    max-width: $content-width;
    margin: 0 auto;
}

// Navigation 
nav {
    background-color: $nav-background;
    border-bottom: 4px solid darken($alternate-background, 5%);
    height: $nav-height;
    float: right;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            float: left;
            position: relative;
            a,
            a:visited {
                display: block;
                padding: 0 20px;
                line-height: $nav-height - 4px;
                color: $nav-font-color;
                text-decoration: none;
                &:hover {
                    color: lighten($primary-color, 10%);
                    text-decoration: none;
                    border-bottom: 4px solid $secondary-color;
                }
                &:not(:only-child):after {
                    padding-left: 4px;
                    content: ' ▾';
                }
            } // Dropdown list
            ul li {
                min-width: 190px;
                a {
                    padding: 15px;
                    line-height: 20px;
                }
            }
        }
    }
}

// Dropdown list binds to JS toggle event
.nav-dropdown {
    position: absolute;
    display: none;
    z-index: 1;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}


/* Mobile navigation */

// Binds to JS Toggle
.nav-mobile {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    height: $nav-height;
    width: $nav-height;
}

@media only screen and (max-width: $breakpoint) {
    // Hamburger nav visible on mobile only
    .nav-mobile {
        display: block;
    }
    nav {
        width: 100%;
        padding: $nav-height 0 15px;
        height: auto;
        ul {
            display: none;
            li {
                float: none;
                a {
                    padding: 15px;
                    line-height: 20px;
                }
                ul li a {
                    padding-left: 30px;
                }
            }
        }
    }
    .nav-dropdown {
        position: static;
    }
    .brand {
        img {
            margin-top: 15px;
        }
    }
}

@media screen and (min-width: $breakpoint) {
    .nav-list {
        display: block !important;
    }
}

#nav-toggle {
    position: absolute;
    left: 18px;
    top: 22px;
    cursor: pointer;
    padding: 10px 35px 16px 0px;
    span,
    span:before,
    span:after {
        cursor: pointer;
        border-radius: 1px;
        height: 5px;
        width: 35px;
        background: $secondary-color;
        position: absolute;
        display: block;
        content: '';
        transition: all 300ms ease-in-out;
    }
    span:before {
        top: -10px;
    }
    span:after {
        bottom: -10px;
    }
    &.active span {
        background-color: transparent;
        &:before,
        &:after {
            top: 0;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}
