/* Scaffolding
  =============== */

// HTML base
html {
  -webkit-font-smoothing: antialiased;
  font: $font-style $font-variant $font-weight #{$font-size}/#{$line-height} $font-family;
  font-size: $font-size;
}

// Body base
body {
  color: $font-color;
  background: $background;
  font-size: $body-font-size;
}

// Headings
h1,
h2,
h3,
h4,
h5 {
  margin: 1.5rem 0;
  font-weight: $heading-font-weight;
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  color: $heading-font-color;
}

// Individual styling
h1 { font-size: $h1; }
h2 { font-size: $h2; }
h3 { font-size: $h3; }
h4 { font-size: $h4; }
h5 { font-size: $h5; }


// Link styling
a {
  color: $link-color;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: $link-hover-color;
    text-decoration: none;
  }
}

// Icon Styling
.fa-5x {
  color: $primary-color;
}

// Highlight
mark {
  background: $highlight;
  padding: 0 0.2rem;
}

// Blockquote
blockquote {
  margin: 0;
  border-left: $bq-border;
  padding: 0 1.5rem;
  font-size: 1.1rem;
  cite {
    display: block;
    margin-top: 25px;
    font-size: .9em;
    text-align: right;
  }
}

// Code block styling
pre {
  border: 0;
  border-radius: $border-radius;
  background: $code-background;
  padding: 1rem;
  tab-size: 2;
  color: $code-color;
  font-family: $code-family;
  font-size: .9rem;
  code {
    font-family: $code-family;
    line-height: 1.2;
  }
}

// Keyboard input
kbd {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  color: #333;
  display: inline-block;
  font-family: $font-family;
  font-size: .8rem;
  line-height: 1.4;
  margin: 0 .1em;
  padding: .1em .6em;
  text-shadow: 0 1px 0 #fff;
}

// Inline code styling
:not(pre) > code {
  color: $code-color;
  background: $code-background;
  font-family: $code-family;
  padding: 0 0.2rem;
  border: $borders;
  border-radius: $border-radius;
}

// Line break
hr {
  height: 0;
  border: 0;
  border-top: $borders;
}

// Definition list
dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
}

// Clean lists
.clean-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

// Full container 
.full-container {
  max-width: $full;
  padding: 0 1rem;
}

// Container
.container {
  max-width: $large;
  padding: 0 1rem;
  margin-left: auto;
  margin-right: auto;
}

// Small container
.small-container {
  @extend .container;
  max-width: $small;
}

// Medium container
.medium-container {
  @extend .container;
  max-width: $medium;
}

// Content
.content-section {
  padding: $content-padding-mobile;
}

@include small-breakpoint {
  .content-section {
    padding: $content-padding;
  }
}