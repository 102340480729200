/* Buttons
  =============== */

// Main button styling
%buttons {
	display: inline-block;
	line-height: 1;
	border: $button-border-width $button-border-style $button-border-color;
	border-radius: $button-border-radius;
	background: $button-background;
	color: $button-color;
	font-weight: $button-font-weight;
	font-family: $button-font-family;
	font-size: $button-font-size;
	text-transform: $button-text-transform;
	padding: .7rem 1.2rem;
	margin: .5rem 0;
	white-space: nowrap;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
}

// Buttons on hover
%buttons-hover {
	border: $button-border-width $button-border-style darken($button-border-color, 10%);
	background: $button-background-hover;
	color: $button-color;
	text-decoration: none;
}

// Buttons on focus
%buttons-focus {
	border: $button-border-width $button-border-style darken($button-border-color, 10%);
	background: darken($button-background, 10%);
	color: $button-color;
	text-decoration: none;
}

// Apply styles to .button class, button element, and button-based inputs
#{$buttons} {
	@extend %buttons;
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
	&:hover {
		@extend %buttons-hover;
	}
	&:focus,
	&:active {
		@extend %buttons-focus;
	}
}

// Secondary button color
.accent-button,
a.accent-button {
	color: $accent-button-color;
	border: 1px solid $accent-button-background;
	background: $accent-button-background;
	&:hover,
	&:focus,
	&:active {
		color: $accent-button-color-hover;
		border: 1px solid darken($accent-button-background, 10%);
		background: darken($accent-button-background, 10%);
	}
}

// Muted buttons
.muted-button,
a.muted-button {
	background: $muted-background;
	border: $muted-border;
	color: $muted-color;
	&:hover,
	&:focus,
	&:active {
		color: $muted-color-hover;
		border: $muted-border-hover;
		background: $muted-background-hover;
	}
}

.white-button,
a.white-button {
	background: #fff;
	border: 1px solid #fff;
	color: $primary-color;
	&:hover,
	&:focus,
	&:active {
		color: $primary-color;
		border: 1px solid darken(#fff, 5%);
		background: darken(#fff, 5%);
	}
}

// Round buttons
.round-button,
a.round-button {
	border-radius: $round-buttons;
}

// Square buttons
.square-button,
a.square-button {
	border-radius: 0;
}

// Full width buttons (block level)
.full-button,
a.full-button {
	display: block;
	width: 100%;
}

// Pagination Links
#pagination>.button:empty {
  display: none;
}
#pagination>.button {
	margin-bottom: 30px;
}
.button>a {
	color: $button-color;
	text-decoration: none;
	margin-bottom: 30px;
}