/* Layout
  =============== */

.thumbnail {
  img {
    display: block;
    margin: 20px auto;
  }
}

#footer {
    font-size: $font-size;

    li {
        display: inline;
        margin-right: 8px;
    }
}
